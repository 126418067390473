import "./Courses.scss"
import "../index.scss";

import Card from "./Cards"
import course_info from "./course_info.json"

import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Courses() {
    const [isEnabled, setIsEnabled] = useState(false);

    function enableShadow() {
	setIsEnabled(true);
    }

    function disableShadow() {
	setIsEnabled(false);
    }
    return (
	    <div>
    	
			{isEnabled ? <div id="shadow_box"> </div> : null}

		<Row xs={1} md={2} lg={3} style={{marginTop: "50px", width: "100%", marginLeft: "0px", marginRight: "0px"}}>

			{
					course_info.map((card) => (
						<Col>
							<Card color={card.color} title={card.title} courses={card.courses} turnOn={enableShadow} turnOff={disableShadow}/>
						</Col>
					))
			}
			</Row>

		</div>
    );
}

export default Courses;
