import "../../index.scss";
import "./Cards.scss";
import Popup from 'reactjs-popup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function isUrl(s) {
   var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
   return regexp.test(s);
}

function Card(props) {
    return (
	    <div className="card-container pop" style={{backgroundColor: props.color}}>
	    <div className="card-title">
	    	{props.title}
	    </div>
	    <div className="divider-popup"></div>
	    <div className="text-holder">
	    {
		Object.keys(props.courses).map((name) => (
		    
			<Popup className="popup-content" contentStyle={{width: "80%", maxWidth: "500px"}} trigger={<li className="course"><a style={{textDecoration: "none"}} className="course-listing border-link"> {name} </a> </li>} onOpen={props.turnOn} onClose={props.turnOff} modal>

		    
			<div className="main-pop-up">
			<Row>
			<Col>
				<div style={{position: "relative", height: "100%"}}>
					<div style={{textAlign: "left", paddingLeft: "30px", paddingTop: "25px", fontSize: "30px"}}>
						{props.courses[name]["abbrev"]}
					</div>
				</div>
				
			</Col>
			<Col>
				<div style={{position: "relative", height: "100%"}}>
					<div style={{textAlign: "right", paddingRight: "30px", paddingTop: "25px", position: "absolute", bottom: "3px", width: "100%", fontSize: "20px"}}>
						{props.courses[name]["prof"]}
					</div>
				</div>
			</Col>
			</Row>
			<div className="divider-popup"></div>
			<div style={{padding: "0px 20px", marginLeft: "20px", fontSize: "35px"}}> Topics:
		    {props.courses[name]["topics"].map((topic) => (
			    <li style={{fontSize: "20px"}}> {topic} </li>
		    ))}
		    </div>
			
			<div className="divider-popup" style={{marginTop: "10px"}}> </div>
			<div style={{padding: "0 20px", paddingBottom: "20px", marginLeft: "20px", fontSize: "35px"}}> Resources:
		    {
			Object.keys(props.courses[name]["resources"] ? props.courses[name]["resources"] : {}).map((resource_name) => {

			    let lnk = props.courses[name]["resources"][resource_name];

			    
			    return (<li style={{fontSize: "20px"}}>
				    {lnk === "" ? <a> {resource_name} </a> :

				     (isUrl(lnk) ? <a style={{outline: "none"}} href={lnk} rel="noopen noreferrer" target="_blank"> {resource_name} </a>:

				     <a href={require("../../Resources/" + props.courses[name]["resources"][resource_name])} rel="noopen noreferrer" target="_blank">
					 {resource_name}
				      </a>)}
				    </li> );
			})

		    }
			</div>
		    </div>
		
		    </Popup>
		
		))
	    }
	</div>

	
	    </div>

    );
}

export default Card
