import './index.scss';
import '../index.scss';


import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import favorite_artist from "../SlideshowImages/favorite_artist.json";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';

function About() {
	const images = [
		require('../SlideshowImages/Melatonin.jpeg'),
		require('../SlideshowImages/Me.jpeg')
	]
	return (
		<Container fluid>
			<Row style={{paddingTop: "100px"}} className="justify-content-center"> 
				<Col xs = {12} md={6} className="text-center"> 
					<div style={{height: "450px", maxWidth: "450px", margin: "0 auto"}}>
						<Fade>
					        {images.map((image, index) => (
					          <div key={index} style={{"height" : "400px"}} className="each-fade">
					              <img style={{ maxWidth: "450px", height: "450px"}} src={image} />
					          </div>
					        ))}
				      	</Fade>
					</div>
			    </Col>

			    <Col xs = {12} md={6} className="text-center"> 
					<div style={{width: "100%", fontSize: "35px", paddingTop: "30px"}}>
						<div >
							<div class="pop" style={{width: "85%", "borderRadius" : "10px", backgroundColor: "#FDCFCF", "padding" : "30px", borderStyle: "solid", margin: "auto"}}>
							Hi! I'm a forth year student studying computer science and math at UCLA. I have experience in both math research and software programming, and
							am looking to secure a full time job that will let me solve difficult problems!
							</div>
						</div>
					</div>
				</Col>
			</Row>
			
			<div id="divider" style={{backgroundColor: "black", width: "100%", height: "3px", marginTop: "50px"}}> </div>
			<Row style={{marginTop: "50px"}}>
				<Col xs = {12} md={6} style={{textAlign: "center"}}> 
					<div>
					<h2 style={{fontSize: "40px"}}> (Academic) Things I've Done </h2>
					<div style={{textAlign: "left"}}>
					<div class = "pop" style={{marginLeft: "auto", marginRight: "auto", width: "85%", borderRadius: "10px", "backgroundColor": "#97e8cc", padding: "30px", fontSize: "20px", borderStyle: "solid", marginBottom: "30px", marginTop: "30px"}}> 
						<h3 style={{marginTop: "10px"}}> Software Stuff </h3>
						<p>
						For the
						{" "}
						<a rel="noopener noreferrer" target="_blank" href="https://www.phylo.org/">CIPRES</a>
						{" "}
 						group, I did some work implementing new functionality and fixing some bugs on their website, using the Struts2 framework.
						</p>
						<p>
						For a larger scale school project, see 
						{" "}
						<a rel="noopener noreferrer" target="_blank" href="https://github.com/8BitRobot/plac">plac</a> which is a Yelp like website designed for programming languages that I worked on with some friends.

						The website is implemented using the React (actually we used the whole MERN stack), which is the same language that I implemented this website in.
						{" "}
						</p>
					</div>
					<div class = "pop" style={{marginLeft: "auto", marginRight: "auto", width: "85%", borderRadius: "10px", "backgroundColor": "#97e8cc", padding: "30px", fontSize: "20px", borderStyle: "solid", marginBottom: "30px", marginTop: "30px"}}> 
						<h3 style={{marginTop: "10px"}}> Math Stuff </h3>
						<p>
						I'm currently working on a project involved in characterizing the Von-Neumann regularity of
						algebras arising from certain dynamical systems.

						</p>

						<p>
						In a completely separate field, I maintain an interest in attacks on various cryptosystems, 
						such as the McEliece cryptosystem that has been proposed to NIST as a post-quantum secure system.
						</p>
					</div>
					<div class="pop" style={{marginLeft: "auto", marginRight: "auto", marginTop: "50px", width: "85%", borderRadius: "10px", "backgroundColor": "#97e8cc", padding: "30px", fontSize: "20px", borderStyle: "solid", marginBottom: "30px"}}> 
						<h3 style={{marginTop: "10px"}}> Competitive Programming </h3>
						<p> In the past, I was an avid competitive programmer. For OI type stuff, you can find the problems I solved on my 
						{" "}
						 <a rel="noopener noreferrer" target="_blank" href="https://oichecklist.pythonanywhere.com/view/d7d12ace57118476cd2db33c30aa30a33f487fcb"> OI Checklist </a>. 
						</p>
						<p>
						For more general stuff, you can find my codeforces account {" "}
						<a rel="noopener noreferrer" target="_blank" href="https://codeforces.com/profile/ijxjdjd">here</a>. I've also written problems for multiple websites, the biggest
						being the Div. 1 contest I created with which can be found {" "}
						<a rel="noopener noreferrer" target="_blank" href="https://codeforces.com/contest/1540">here</a>. In addition to this, I've tested 30+ codeforces rounds and provided feedback to the contest authors.
						</p>
						<p>
						I still participate in competitive programming through ICPC, but I'm no longer active in the community.
						</p>
					</div>
					</div>
					</div>
				 </Col>
				 <Col xs={12} md={6} style={{textAlign: "center"}}>
					<div>
					<h2 style={{fontSize: "40px"}}> Things I Have Experience With </h2>
		    		<div class="pop" style={{margin: "auto", width: "85%", borderRadius: "10px", backgroundColor: "#babcff", padding: "30px", borderStyle: "solid", marginTop: "30px"}}>
				    <div style={{fontSize: "20px", textAlign: "left"}}>
						<h3 style={{marginTop: "10px"}}> Programming Languages </h3>
						<ul>
						<li> C/C++ </li>
						<li> Java </li>
						<li> Python </li>
						</ul>
						<h3 style={{marginTop: "10px"}}> CS Topics </h3>
						<ul>
						<li> Algorithm Research </li>
						<li> Multithreading </li>
						<li> Lower-Level Optimization </li>
						<li> General Optimization </li>
						</ul>
						<h3 style={{marginTop: "10px"}}> Databases </h3>
							<ul>
							<li> SQL </li>
							<li> MongoDB </li>
							</ul>
						</div>
					    </div>
					</div>
				</Col>
			</Row>


		</Container>
	);
}

export default About;
