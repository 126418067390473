import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';

import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import './index.scss';
import reportWebVitals from './reportWebVitals';

import Header from "./shared/Header";
import Home from "./Home";
import Courses from "./Courses";
import About from "./About";
import Experience from "./Experience";

const root = ReactDOM.createRoot(document.getElementById('root'));
const TRACKING_ID = "G-6GVX59QVMM";
ReactGA.initialize(TRACKING_ID);


document.body.style.background = "#faf8f7";
document.body.style.margin = "0px";
document.body.style.padding = "0px";
document.body.width = "100%";
document.margin = "0px";

root.render(
    <React.StrictMode>
	<Header />
	<BrowserRouter>
	<Routes>
	    <Route path="/" element={<About/>}/>
	    <Route path="/courses" element={<Courses/>} />
	    <Route path="/about" element={<About/>}/>
	    <Route path="/experience" element={<Experience/>}/>
	</Routes>
	</BrowserRouter>
  </React.StrictMode>
);


const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
