import "./Header.scss";
import "../../index.scss";

import { useEffect, useState } from "react";

import github_logo from "../../assets/github.png";
import linkedin_logo from "../../assets/linkedin.png";
import gmail_logo from "../../assets/gmail.png";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';

function Header() {
    return (
		    <Navbar className="header-bar" expand="lg">
			    <Navbar.Toggle aria-controls="basic-navbar-nav" />
		    	<Navbar.Brand> 
	    <a href="/about"  style={{marginRight: "20px", padding: "0px min(20px, 5%)", textAlign: "center", textDecoration: "none"}}> <h1 style={{fontSize: "35px"}}> Allen Zhang </h1> </a> 
	    		</Navbar.Brand>
			    <Navbar.Collapse id="basic-navbar-nav">
				    <Row id="header-links" className="justify-content-center row-12">
				        <Col lg={4} xl={"auto"} className="text-center">
					    	<a id = "header-about" href="/about" style={{padding: "0px 20px"}} >about</a>
					    </Col>
					    <Col md={4} xl={"auto"} className="text-center" >
					   		 <a id="header-courses"  href="/courses"  style={{padding: "0px 20px"}}> courses </a>
	    </Col>
	    <Col style={{flex: 1}} className="text-center d-none d-xl-block"> </Col>
					    <Col lg={4} xl={"auto"} className="text-center">
					    	<a href="mailto:allenz@g.ucla.edu" style={{padding: "0px 10px"}} > <img src={gmail_logo}  alt={"Gmail"}/> </a>
					    </Col>
					    <Col md={4} xl={"auto"} className="text-center">
					    	<a href="https://github.com/Ausca" target="_blank" style={{padding: "0 10px"}}> <img src={github_logo}  alt={"GitHub"} target="_blank"/> </a>
					    </Col>
					    <Col md={4} xl={"auto"} className="text-center">
					    	<a href="https://www.linkedin.com/in/allen-zhang-b0200b228/" target="_blank" style={{padding: "0 10px"}}>  <img src={linkedin_logo}  alt={"LinkedIn"}/> </a>
					    </Col>
			        </Row>
                </Navbar.Collapse>

		    </Navbar>

    );
}

export default Header;
